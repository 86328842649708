// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

// custom colors
.site-header {
  border-top: 0;
  border-bottom: 0;
  background: $brand-color;
  .page-link {
    color: $site-title-color;
  }
}

.site-footer {
  border-top: 5px solid $border-color-01;
}

.site-nav {
  background-color: $brand-color;
  // border: 1px solid $site-title-color;
  border: none;
  .menu-icon {
    > svg path {
        fill: $site-title-color;
    }
  }
}

table {
  border: 0;
}

// for posts page links
.post-link:visited {
  color: $text-color-2;
}
.post-link, .post-link:hover {
  color: $text-color;
}

// for gallery
.sensitive {
  position: relative;
  #center {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: grayscale(0.5) blur(40px);
    .spoiler-show {
      border-radius: 5px;
      font-weight: 600;
      border: 2px solid $site-title-color;
      color: $site-title-color;
      height: 2rem;
      background-color: rgba($lm-brand-color, .4);
    }
    .spoiler-show:hover {
      background-color: rgba($lm-brand-color, 1);
    }
  }
}

.gallery-item {
  .credits {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 0.25rem 0;
    a {
      padding: 0 0.5rem;
    }
  }
}

// social media list
.social-media-list li a, .social-media-list li a:visited {
  color: $text-color;
  border: 2px solid $border-color-01;
  border-radius: 3px;
  .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.social-media-list li a:hover {
  border: 2px solid $text-color;
  color: $brand-color;
}
.social-item {
  color: $text-color
}

// for cookie notice
#cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none; // this is because it is set properly in the javascript
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 0.5rem 1rem;
  width: calc(100vw - 2rem);
  background-color: $brand-color;
  font-weight: 600;
  color: $site-title-color;
  button {
    border: 2px solid $site-title-color;
    font-weight: 600;
    background-color: $brand-color;
    border-radius: 3px;
    padding: 3px;
    display: inline-block;
    cursor: pointer;
    color: $site-title-color;
    svg {
      fill: $site-title-color;
      width: 18px;
      height: 18px;
    }
  }
  span {
    order: 1;
  }
  #cookie-notice-dismiss {
    border: none;
    order: 4;
  }
  a {
    order: 2;
    color: $brand-color-dark;
  }
  a:hover {
    color: $brand-color-light;
  }
  #cookie-notice-accept {
    order: 3;
  }
  button:hover {
    background-color: $site-title-color;
    color: $brand-color-dark;
    transition-duration: 0.2s;
    svg {
      fill: $brand-color-dark;
    }
  }
}

@media (max-width: 599px) {
  .site-nav {
    border: 2px solid $site-title-color;
  }
  #cookie-notice {
    span {
      width: calc(100% - 30px);
      align-self: left;
    }
    #cookie-notice-dismiss {
      border: none;
      order: 2;
      flex-break: after;
    }
    a {
      order: 3;
    }
    #cookie-notice-accept {
      order: 4;
    }
  }
}

// stolen from: https://codepen.io/rikschennink/pen/mjywQb
.speech-bubble::before {
  // layout
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 1.5em; // offset should move with padding of parent
  border: 1rem solid transparent;
  border-top: none;

  // looks
  border-bottom-color: $background-color;
  filter: drop-shadow(0 -0.095rem 0.0625rem $border-color-01);
}
.speech-bubble {
  // layout
  position: relative;
  max-width: 30em;
  
  // looks
  background-color: $background-color;
  padding: 1.125em 1.5em;
  font-size: 1.0rem;
  border-radius: 1rem;
  border: 0.0625rem solid $border-color-01;
  box-shadow:	0.5rem 0.5rem 0.5rem $border-color-01;//, -0.125rem -0.125rem 0.125rem $border-color-01;
}
